import * as React from "react"
import { useState } from 'react'
import { Layout } from "../components/layout"
import { Seo } from "../components/common"
import { TagButtonList, BlogPosts } from "../components/blogs"
import { Skeleton } from '@mui/material'

const BlogIndex = ({ pageContext }) => {
  const [item, setItem] = useState(false)
  return (
    <Layout>
        <img
        className="rounded-md shadow-lg mt-3 w-full"
        src={'https://picsum.photos/800/300'}
        alt="My Image"
        onLoad={() => setItem(true)}
        style={{ display: item ? 'block' : 'none', height: '385px' }}
      />
      {!item && <Skeleton variant="rect" className="rounded-md shadow-lg mt-3 w-full" height={385} />}
        <p className='text-center mt-3 text-xl text-transparent bg-clip-text bg-gradient-to-r from-red-500 to-blue-500'>"庾信平生最萧瑟，暮年诗赋动江关。" - 杜甫</p>
      <div className="mt-16">
        <TagButtonList checkedName={pageContext.tagName} />
      </div>
      <BlogPosts tagName={pageContext.tagName} />
    </Layout>
  )
}

export default BlogIndex

export const Head = () => <Seo title="Tag Posts" />
